<template>
  <section id="dashboard-analytics">
    <!-- Stats Card Horizontal -->
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="me.money"
          statistic-title="账号余额"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          statistic="0"
          statistic-title="产品"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          statistic="0"
          statistic-title="推荐用户"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"

          statistic="0"
          statistic-title="工单"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="4"
      >
        <b-card title="更新公告">
          <app-timeline>
            <app-timeline-item
              v-for="post in posts"
              :key="post.id"
              :title="post.Title"
              :subtitle="post.Body"
              :time="formatDate(post.CreatedAt)"
              variant="success"
            />
          </app-timeline>
        </b-card>
      </b-col>
      <b-col
        lg="8"
      >
        <b-card title="流量使用统计">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="mb-1 mb-sm-0">
              <span class="text-muted">Traffic (MB)</span>
            </div>
            <div class="d-flex align-content-center mb-1 mb-sm-0">
              <h1
                v-if="false"
                class="font-weight-bolder "
              >
                $ 183,382
              </h1>
              <div
                v-if="false"
                class="pt-25 ml-75"
              >
                <b-badge variant="light-secondary">
                  <feather-icon
                    icon="ArrowDownIcon"
                    size="12"
                    class="text-danger align-middle"
                  />
                  <span class="font-weight-bolder align-middle"> 24%</span>
                </b-badge>
              </div>
            </div>
          </div>

          <!-- echart -->
          <app-echart-line
            :option-data="trafficStatLine"
            :settings="trafficStatChartSettings"
            :extend="trafficStatChartExtend"
          />

          <chartjs-component-line-chart
            v-if="false"
            :height="400"
            :data="trafficStatLine"
            :settings="trafficStatChartSettings"
            :extend="trafficStatChartExtend"
          />

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import useHttp from '@/http/useHttp'
import Ripple from 'vue-ripple-directive'
import { notify, throwRes } from '@/utils/utils'
import { formatDate } from '@/utils/format-date'
import {
  BRow, BCol, BCard, BBadge,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { kFormatter } from '@core/utils/filter'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ChartjsComponentLineChart from '@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentLineChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    StatisticCardHorizontal,
    AppEchartLine,
    AppTimeline,
    AppTimelineItem,
    ChartjsComponentLineChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      me: {
        money: '-',
      },
      // data: [],
      posts: [],
      trafficStat: [],

      trafficStatLine: {
        xAxisData: [],
        series: [],
      },
      trafficStatChartExtend: {
        // 纵坐标
        yAxis: {
          axisLabel: {
            formatter: '{value} MB',
          },
        },
      },
      trafficStatChartSettings: {
        labelMap: {
          Value: '流量',
        },
        metrics: ['Value'],
        dimension: ['Time'],
      },
      trafficStatChartData: {
        columns: ['Time', 'Value'],
        rows: [
        ],
      },
    }
  },
  created() {
    // data
    this.loadData()
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    loadData() {
      this.loadme()
      this.loadBlog()
      this.loadChart()
    },
    loadBlog() {
      useHttp.getBlogPost().then(res => {
        const { data } = res.data
        this.posts = data
        throwRes(data)
      }).catch(error => {
        notify('Error', error.toString(), 'danger')
      })
    },
    loadme() {
      useHttp.getMe().then(res => {
        this.me = res.data.data.user
      }).catch(error => {
        notify('Error', error.toString(), 'danger')
      })
    },
    loadChart() {
      useHttp.getUserTrafficStat().then(res => {
        const { data } = res.data
        data.map(item => {
          const value = (item.Value / (1024 ** 2)).toPrecision(3)
          const time = this.formatDate(item.Time)
          this.trafficStatChartData.rows.push({
            Value: value,
            Time: time,
          })
          this.trafficStatLine.xAxisData.push(time)
          this.trafficStatLine.series.push(value)
          return item
        })
      }).catch(error => {
        notify('Error', error.toString(), 'danger')
      })
    },
    kFormatter,
    formatDate,
  },
}
</script>
